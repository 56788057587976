import { MainNav } from "./MainNav";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaTwitter,
  FaSnapchatGhost,
  FaYoutube,
  FaLinkedinIn
} from "react-icons/fa";
import { RiInstagramFill, RiWhatsappFill } from "react-icons/ri";
import { SlLocationPin } from "react-icons/sl";
import { IoMailOutline } from "react-icons/io5";
import { GiModernCity } from "react-icons/gi";
export const MainLayout = (props) => {
  const merchant = useSelector((state) => state.configStore.merchant);
  const { t } = useTranslation();
  return (
    <div className="MainLayout d-flex flex-column min-vh-100">
      <MainNav />
      <div className="Content">{props.children}</div>
      <footer id="footer">
        <div className="section pb-0">
          <div className="container">
            <div className="row pb-4 md-py-5">
              <div className="col-md-4 col-sm-12">
                <div className={"footer-info"}>
                  <div>
                    <NavLink className={"footer-logo"} to="/">
                      <Image
                        src={merchant.logo ?? "/logo.png"}
                        className="footer-img"
                      />
                    </NavLink>
                  </div>
                  <div className={"footer-long-text text-start mt-3"}>
                    {merchant.description}
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center flex-column d-flex justify-content-start align-items-start flex-wrap footer-payments">
                <h1 className="footer-title mb-2 text-start">
                  {t("Social media info")}
                </h1>
                {merchant.facebook_link && (
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="social-icon me-2">
                      <FaFacebookF />
                    </div>
                    <a
                      href={merchant.facebook_link}
                      className="footer-icon ms-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Facebook")}
                    </a>
                  </div>
                )}
                {merchant.instagram_link && (
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="social-icon me-2">
                      <RiInstagramFill />
                    </div>
                    <a
                      href={merchant.instagram_link}
                      className="footer-icon ms-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Instagram")}
                    </a>
                  </div>
                )}
                {merchant.linkedin_link && (
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="social-icon me-2">
                      <FaLinkedinIn />
                    </div>
                    <a
                      href={merchant.linkedin_link}
                      className="footer-icon ms-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Linked in")}
                    </a>
                  </div>
                )}
                {merchant.youtube_link && (
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="social-icon me-2">
                      <FaYoutube />
                    </div>
                    <a
                      href={merchant.youtube_link}
                      className="footer-icon ms-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Youtube")}
                    </a>
                  </div>
                )}
                {merchant.snapchat_link && (
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="social-icon me-2">
                      <FaSnapchatGhost />
                    </div>
                    <a
                      href={merchant.snapchat_link}
                      className="footer-icon ms-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Snapchat")}
                    </a>
                  </div>
                )}
                {merchant.twitter_link && (
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="social-icon me-2">
                      <FaTwitter />
                    </div>
                    <a
                      href={merchant.twitter_link}
                      className="footer-icon ms-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Twitter")}
                    </a>
                  </div>
                )}
                {merchant.whatsapp_link && (
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="social-icon me-2">
                      <RiWhatsappFill />
                    </div>
                    <a
                      href={merchant.whatsapp_link}
                      className="footer-icon ms-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Whatsapp")}
                    </a>
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-start">
                <div
                  className={
                  "footer-info d-inline-flex flex-column justify-content-start align-items-start"
                  }
                >
                  <h1 className="footer-title mb-2 text-start">
                    {t("Contact info")}
                  </h1>
                  {merchant.phone && (
                    <div className={"d-flex  f-soc-row"}>
                      <div className={"footer-key"}>
                        {/* <i className="fa fa-phone-alt me-2"></i> */}
                        <p className="mb-2">
                          {t("Have questions? contact us")}
                        </p>
                        <span className={"footer-value mt-1"}>
                          {merchant.phone}
                        </span>
                      </div>
                    </div>
                  )}
                  {merchant.email && (
                    <div className={"d-flex f-soc-row"}>
                      <div className={"footer-key"}>
                        <IoMailOutline className="me-2" />
                        <span className={"footer-value ms-1"}>
                          {merchant.email}
                        </span>
                      </div>
                    </div>
                  )}
                  {merchant.address && (
                    <div className={"d-flex f-soc-row"}>
                      <div className={"footer-key"}>
                        <SlLocationPin className="me-2" />
                        <span className={"footer-value ms-1"}>
                          {merchant.address}
                        </span>
                      </div>
                    </div>
                  )}
                  {merchant.city && (
                    <div className={"d-flex f-soc-row"}>
                      <div className={"footer-key"}>
                        <GiModernCity className="me-2" />
                        <span className={"footer-value ms-1"}>
                          {merchant.city}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="col text-center copyright">جميع الحقوق محفوظة لدى {merchant.company_name} © 2023</div> */}
            </div>
            <div
              id="powered-footer"
              className="section d-flex justify-content-center align-items-center py-3"
            >
              <a
                href={`https://eshtarek.app?utm_source=${window.location.origin}&utm_id=PoweredBy`}
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-0 me-1 d-inline">{t("Powered By Eshtarek")}</p>
                <img className="rounded" src="/favicon.ico" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
